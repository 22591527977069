.ui-select {
  position: relative;

  & > input {
    position: absolute;
    left: -9999px;
  }

  & + .invalid-feedback {
    display: block;
  }

  .dropdown-toggle {
    background: #fff;
    &.active,
    &:active,
    &:focus {
      border: 1px solid map-get($theme-colors, "primary");
      box-shadow: none !important;
    }
    &.is-invalid {
      border: 1px solid map-get($theme-colors, "danger");
    }
  }

  /* */
  &.show .dropdown-toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1001;
  }

  .dropdown-menu {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow-x: hidden;
    max-height: 200px;
    overflow: auto;
    border: none;
    width: 100%;
    margin: 0;

    & .dropdown-item {
      &:focus,
      &:active {
        background-color: map-get($theme-colors, "primary");
        color: #fff;
        outline: none;
      }
    }
  }

  & > .label {
    display: flex;

    & > button {
      justify-content: space-between;
      line-height: 0.9rem;
      align-items: center;
      font-size: 0.9rem;
      text-align: left;
      padding: 1rem;
      display: flex;
      flex: 1;
    }
  }
}
