.ui-checkbox {
  $ui-checkbox-size: 1.5rem;

  label {
    color: $ui-font-color-dark;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
    display: flex;

    & > span.text {
      line-height: $ui-checkbox-size;
      user-select: none;
      font-size: 0.9rem;
    }
  }

  label:after {
    display: table;
    content: '';
    clear: both;
  }

  .cr {
    height: $ui-checkbox-size;
    width: $ui-checkbox-size;
    border-radius: 0.25em;
    display: inline-block;
    margin-right: 0.5em;
    position: relative;
    text-align: center;
    background: #fff;
    line-height: 2.2;
  }

  .cr .cr-icon {
    line-height: $ui-checkbox-size / 2;
    font-size: $ui-checkbox-size / 2;
    color: #000;
  }

  label input[type='checkbox'] {
    display: none;
  }

  label input[type='checkbox'] + .cr > .cr-icon {
    opacity: 0;
  }

  label input[type='checkbox']:checked + .cr > .cr-icon {
    opacity: 1;
  }

  label input[type='checkbox']:disabled + .cr {
    opacity: 0.5;
  }
}
