// bootstrap variables
$body-bg: #fff;
$body-color: #999;
$enable-shadows: true;

$grid-gutter-width: 1rem;

$theme-colors: (
  'primary': #2a69fa,
  'success': #45dfb1,
  'danger': #f63d51,
  'light': #fff
);

$font-family-base: 'Nunito Sans', sans-serif;

// custom
$ui-font-color-dark: #000;
$ui-menu-icon-size: 22px;
$ui-caption-color: #999;
$ui-bg-color: #f7f7f7;
$ui-padding: 24px;
$ui-td-spacing: 5%;
$ui-max-form-width: 600px;

/*
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
*/

@import 'node_modules/bootstrap/scss/bootstrap';

// Base components
@import './DL/dl';
@import './A/a';
@import './P/p';
@import './Li/li';
@import './Typo/typo';
@import './Button/button';
@import './Progress/wave';
@import './Progress/linear';
@import './FormField/formfield';
@import './FormField/Radio/radio';
@import './FormField/Select/select';
@import './FormField/Slider/slider';
@import './FormField/CheckBox/checkbox';
@import './FormField/DatePicker/datepicker';

// Page blocks
@import './_blocks/Menu/menu';
@import './_blocks/NavBar/navbar';
@import './_blocks/Calendar/calendar';
@import './_blocks/Snackbar/snackbar';
@import './_blocks/AppFooter/appfooter';
@import './_blocks/MessageBox/messagebox';
@import './_blocks/BigLoading/bigloading';
@import './_blocks/CarListCard/carlistcard';
@import './_blocks/OccupancyTable/occupancytable';

// Containers
@import './_containers/form';
@import './_containers/login';
@import './_containers/common';
@import './_containers/cardetail';
@import './_containers/fullscreen';

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
    url(https://fonts.gstatic.com/s/nunitosans/v3/pe0qMImSLYBIv1o4X1M8ccezI9tScg.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 700;
  src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
    url(https://fonts.gstatic.com/s/nunitosans/v3/pe03MImSLYBIv1o4X1M8cc8GBs5jU1EQVg.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

html {
  min-height: 100%;
}

body,
html {
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
}

body,
#app-root {
  background: #f7f7f7;
  min-height: 100%;
  flex: 1;
}

#app-root {
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  min-height: 100%;
  max-width: 100%;
  display: flex;
  width: 100%;
}

#app-root.fixed {
  overflow-y: hidden;
  max-height: 100%;
}

.ui-container {
  max-width: 800px;
}

.ui-container-row-fluid {
  margin-right: -$ui-padding;
  margin-left: -$ui-padding;
}
.shadow {
  @include box-shadow(0 4px 10px rgba(0, 0, 0, 0.15) !important);
}

.graphiql-container {
  flex: 1;
}
.graphiql-container *,
.graphiql-container *::before,
.graphiql-container *::after {
  box-sizing: content-box;
}

@import url('./fonts.css');

.card-protocols-item {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: none;

  .title {
    display: flex;
    color: #000;

    & > span {
      margin-left: auto;
    }
  }

  .secondary {
    display: flex;
    & > a {
      margin-left: auto;
    }
  }
}

.protocols-sort {
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  display: flex;
}
