.ui-progress-wave {
  position: relative;
  text-align: center;
  margin: $ui-padding / 2 auto;
  height: $ui-padding;
  padding-top: $ui-padding / 3;

  > span {
    display: inline-block;
    width: $ui-padding * 2/3;
    height: $ui-padding * 2/3;
    border-radius: 50%;
    margin-right: $ui-padding / 2;
    background: map-get($theme-colors, 'primary');
    animation: ui-progress-wave 1.3s linear infinite;
    @include box-shadow(2px 4px 8px '#2a69fa54');

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }

  @keyframes ui-progress-wave {
    0%,
    60%,
    100% {
      transform: initial;
    }

    30% {
      transform: translateY(-$ui-padding/3);
    }
  }
}
