.ui-container-login {
  flex-direction: column;
  background: white;
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;

  .ui-container-login-footer {
    margin-top: auto;
    align-self: center;
  }
}

.ui-container-login-logo {
  align-items: center;
  max-height: 40%;
  display: flex;
  flex: 1;

  & > img {
    max-height: 75px;
    max-width: 100%;
    margin: auto;
  }
}

.ui-container-login-form-container {
  flex-direction: column;
  position: relative;
  padding: 1.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex: 1;

  & > * {
    position: relative;
  }

  &::before {
    background: #f7f7f7;
    position: absolute;
    display: block;
    content: "";
    left: 50px;
    bottom: 0;
    right: 0;
    top: 0;
  }
}
