.ui-date-picker {
  .form-control[readonly] {
    background: #fff;
  }
}

.ui-date-picker-popup {
  & > * {
    @extend .shadow;
  }
  border-top: $ui-padding solid transparent;
  position: relative;

  &:before {
    border-right: $ui-padding solid transparent;
    border-left: $ui-padding solid transparent;
    border-bottom: $ui-padding solid #fff;
    width: $ui-padding * 2;
    left: $ui-padding / 2;
    height: $ui-padding;
    position: absolute;
    top: -$ui-padding + 1px;
    content: '';
  }

  &.right {
    &:before {
      right: $ui-padding / 2;
      left: auto;
    }
  }
}

/**
 * Body popup
 */
.ui-body-popup {
  position: absolute;
  overflow: visible;
  z-index: 100;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  & > .ui-body-popup-content {
    position: absolute;
    z-index: 101;

    .ui-date-picker-popup {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}
