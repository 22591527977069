.ui-radio {
  & > .ui-radio-wrapper {
    display: flex;
    justify-content: space-around;

    & > label {
      display: flex;
      color: $ui-font-color-dark;
      position: relative;
      overflow: hidden;

      & > input {
        visibility: hidden;
        position: absolute;
        left: -9999px;
      }

      & > .sign {
        margin-right: $ui-padding / 2;
        border: 8px solid #fff;
        height: $ui-padding;
        border-radius: 100%;
        width: $ui-padding;
        background: #fff;
      }

      &.checked .sign {
        border-color: map-get($theme-colors, "primary");
      }
    }
  }
}
