.ui-container-form {
  @extend .ui-container-common;

  background: $ui-bg-color !important;

  .section-header {
    @include box-shadow(0 0 1.5rem rgba(0, 0, 0, 0.15) !important);
    padding: $ui-padding * (2/3) $ui-padding;
    margin: ($ui-padding) 0 - $ui-padding;
    color: $ui-font-color-dark;
    align-items: center;
    background: #fff;
    font-weight: bold;
    font-size: 0.8rem;
    display: flex;

    &:first-of-type {
      margin-top: -$ui-padding;
    }

    & > i {
      margin-right: $ui-padding / 2;
      font-size: 1.5rem;
    }
  }

  @media (min-width: $ui-max-form-width) {
    .ui-form-group,
    .ui-btn-group,
    .ui-typo,
    .row {
      max-width: $ui-max-form-width;

      margin-right: auto;
      margin-left: auto;
    }
  }
}

.ui-container-form-footer {
  @include box-shadow(0 0 1.5rem rgba(0, 0, 0, 0.15) !important);
  padding: $ui-padding * 2 $ui-padding;
  flex-direction: column;
  margin: -$ui-padding;
  margin-top: $ui-padding;
  background: #fff;
  display: flex;

  .ui-button + .ui-button {
    margin-left: 0;
  }
}
