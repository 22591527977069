.ui-block-calendar {
  overflow-x: hidden;
  background: #fff;
  display: block;
  width: 100%;

  & > header {
    @extend .shadow;

    & > nav {
      padding: $ui-padding $ui-padding / 2 $ui-padding / 2 $ui-padding / 2;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      display: flex;
      color: #000;

      & > i {
        cursor: pointer;
      }

      & > h3 {
        line-height: $ui-padding / 2;
        margin: 0 $ui-padding * 2/3;
        font-size: $ui-padding / 2;
        text-transform: uppercase;
      }
    }
  }

  & table {
    width: calc(100% - 1.5rem);
    max-width: 100%;
    margin: 0 $ui-padding / 2;
    & > thead {
      & > tr > th {
        padding: $ui-padding / 2 0;
        text-transform: uppercase;
        font-weight: normal;
        text-align: center;
        font-size: 0.8em;
        color: #ccc;
      }
    }

    & > tbody {
      & > tr > td {
        text-align: center;
        line-height: 0.8em;
        position: relative;
        font-size: 0.8em;
        color: #000;
        padding: 0;

        & > span:first-child {
          position: relative;
          display: block;
          height: 0;
          padding-bottom: 100%;
          width: 100%;
        }

        & > span:last-child {
          justify-content: center;
          align-content: center;
          align-items: center;
          position: absolute;
          display: flex;
          z-index: 3;
          bottom: $ui-td-spacing;
          right: $ui-td-spacing;
          left: $ui-td-spacing;
          top: $ui-td-spacing;
        }

        &.today > span:last-child {
          background: rgba(0, 0, 0, 0.15);
          border-radius: 100%;
        }
        &.today {
          font-weight: bold;
        }

        &.inactive {
          color: #ccc;
        }

        &.color-secondary:before {
          background: lighten(map-get($theme-colors, "success"), 30%);
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          z-index: 0;
          bottom: $ui-td-spacing;
          top: $ui-td-spacing;
        }

        &:last-of-type.color-secondary:before {
          right: -$ui-padding / 2;
        }

        &:first-of-type.color-secondary:before {
          left: -$ui-padding / 2;
        }

        &.rounded-left,
        &.rounded-right,
        &.rounded-both {
          & > span {
            color: #fff;
          }
          &:after {
            background: map-get($theme-colors, "success");
            border: 5px solid transparent;
            border-radius: 100%;
            position: absolute;
            content: "";
            z-index: 0;
            bottom: $ui-td-spacing;
            right: $ui-td-spacing;
            left: $ui-td-spacing;
            top: $ui-td-spacing;
          }
          &.color-primary {
            &:after {
              background: map-get($theme-colors, "primary");
            }
          }
        }

        &.rounded-both:after {
          background: darken(map-get($theme-colors, "success"), 15%);
        }
        &.color-primary.rounded-both:after {
          background: darken(map-get($theme-colors, "primary"), 15%);
        }

        &.rounded-left {
          &.color-secondary:before {
            left: 50%;
          }
        }
        &.rounded-right {
          &.color-secondary:before {
            right: 50%;
          }
        }
      }
    }
  }

  & > table {
    margin: $ui-padding / 2;

    td.clickable {
      cursor: pointer;
    }
  }
}
