.ui-a {
  &.caption {
    font-size: 0.8rem;
    color: $ui-caption-color;
  }

  &.center {
    text-align: center;
    display: block;
  }
}
