.ui-snackbar {
  position: fixed;
  z-index: 3000;
  display: flex;
  justify-content: center;

  bottom: $ui-padding;
  right: $ui-padding;
  left: $ui-padding;

  & > .ui-snackbar-content {
    @include box-shadow(0 10px 25px rgba(0, 0, 0, 0.25));
    background-color: #333;
    padding: $ui-padding / 2;
    align-items: center;
    border-radius: 3px;
    text-align: left;
    display: flex;
    color: #fff;

    & > span {
      flex: 1;
    }

    & > i {
      font-size: $ui-padding;
    }
  }

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    & > .ui-snackbar-content {
      flex: 1;
      width: 100%;
    }
  }

  &.success {
    .ui-snackbar-content {
      background-color: map-get($theme-colors, "success");
      color: #fff;
    }
  }

  &.danger {
    .ui-snackbar-content {
      background-color: map-get($theme-colors, "danger");
      color: #fff;
    }
  }

  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  &.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }

  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s ease-in;
  }
}
