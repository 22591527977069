$uiContainerCarDetailIconSize: 1rem;

.ui-container-car-detail {
  position: relative;
  padding-top: 60px;
  min-height: 100%;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  @extend .shadow;
  background: #fff;

  .ui-container-car-detail-image {
    position: relative;
    display: block;

    img.placeholder {
      max-width: 100%;
      width: 100%;
    }

    div.carPhoto {
      background-position: center center;
      background-size: cover;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
    }

    a {
      color: map-get($theme-colors, 'primary');
      padding: $ui-padding * (2/3);
      bottom: $ui-padding * 1.5;
      border-radius: 100%;
      position: absolute;
      background: #fff;
      line-height: 1;

      & > i {
        line-height: $uiContainerCarDetailIconSize;
        font-size: $uiContainerCarDetailIconSize;
        height: $uiContainerCarDetailIconSize;
        width: $uiContainerCarDetailIconSize;
      }
    }

    a.editIcon {
      right: $ui-padding * 1.5;
    }

    a.bookIcon {
      right: $ui-padding * 5;
      background: map-get($theme-colors, 'primary');
      i.icon-calendar:before {
        color: #fff !important;
      }
    }
  }

  .ui-container-car-detail-content {
    margin: $ui-padding;
    position: relative;
    max-width: 100%;
  }
}
