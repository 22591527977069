.ui-typo {
  &.heading {
    margin-bottom: $ui-padding / 2;
    color: $ui-font-color-dark;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    display: block;
  }

  &.body1 {
    color: $ui-font-color-dark;
  }

  &.caption {
    font-size: 0.75em;
    text-transform: uppercase;
  }
}
