// @import "node_modules/bootstrap/scss/buttons";

.ui-button {
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  display: flex;
  float: left;

  &:not(.btn-lg):not(.btn-sm) {
    padding: $ui-padding / 3 $ui-padding * 2/3;
    font-size: 0.75rem;
  }
  &.btn-lg {
    height: calc(2.875rem + 2px); // magic from LG input
    padding: $ui-padding * (2/3);
    font-size: 0.9rem;
    line-height: 1;
  }
  & > i {
    font-size: 1rem;
  }

  &:not(.btn-vertical) > i + * {
    margin-left: $ui-padding / 2;
  }

  &:not(.btn-vertical) > * + i {
    float: right;
    margin-left: $ui-padding / 2;
  }

  &:not(.btn-vertical) > i + * {
    float: right;
    margin-left: $ui-padding / 2;
  }

  &.btn-center {
    & > i {
      margin-left: $ui-padding / 2;
    }

    text-align: center;
    padding-right: 0;
    padding-left: 0;
  }

  &.btn-vertical {
    flex-direction: column;
    & > i {
      font-size: 1.3rem;
      margin-bottom: $ui-padding / 3;
    }
  }

  &.btn-icon {
    & > i {
      margin: 0 $ui-padding / 3;
    }
    & > span {
      display: none;
    }
  }

  &.shadow {
    box-shadow: 0 5px 10px #dcdcdc !important;
  }

  &.rounded {
    border-radius: 24px !important;
  }
}

.btn-group-block {
  display: flex;
  & > .btn {
    flex: 1;
  }
}

.ui-button.btn-success {
  color: map-get($theme-colors, "success") !important;
}

.btn-outline-success:active,
.btn-outline-success:hover,
.btn-success:not(.btn-link),
.btn-success:hover {
  color: #fff !important;
}

.ui-button + .ui-button {
  margin-left: $ui-padding / 2;
}

.ui-button.btn-link {
  border: none !important;
  font-weight: bold;
  padding: 0;
}
