.ui-block-occupancy-table {
  $ui-table-border-color: #efefef;

  margin: $ui-padding 0 - $ui-padding;

  & > header {
    & > nav {
      padding: $ui-padding $ui-padding / 2 $ui-padding / 2 $ui-padding / 2;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      display: flex;
      color: #000;

      & > i {
        cursor: pointer;
      }

      & > h3 {
        line-height: $ui-padding / 2;
        margin: 0 $ui-padding * 2/3;
        font-size: $ui-padding / 2;
        text-transform: uppercase;
      }
    }
  }

  .ui-block-occupancy-table-wrapper {
    position: relative;
    overflow: hidden;
    font-size: 12px;
    display: flex;
    width: 100%;
    padding-bottom: $ui-padding;

    & > div:first-child {
      @include box-shadow(0 0 15px rgba(0, 0, 0, 0.5));
      position: absolute;
      height: 1px;
      width: 100%;
      top: -1px;
    }

    & > div:last-child {
      @include box-shadow(0 0 15px rgba(0, 0, 0, 0.5));
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
    }

    .ui-fixed-sidebar {
      flex-direction: column;
      position: relative;
      max-width: 60%;
      display: flex;
      z-index: 3;

      .cars-table {
        flex-direction: column;
        display: flex;
        flex: 1;

        & > div {
          justify-content: space-between;
          align-items: center;
          position: relative;
          display: flex;
          width: 100%;
          flex: 1;

          & > div {
            color: $ui-font-color-dark;

            &:first-child {
              padding-left: $ui-padding * (2/3);
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            &:last-child {
              padding-right: $ui-padding * (2/3);
              white-space: nowrap;
              min-width: 85px;
            }
          }

          div + div {
            padding-left: $ui-padding / 3;
          }
        }
      }

      &.scrolled {
        .cars-table > div:after {
          position: absolute;
          right: -1rem;
          opacity: 0.5;
          height: 100%;
          width: 1rem;
          content: '';
          top: 0;

          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.25) 30%,
            rgba(0, 0, 0, 0.05) 70%,
            rgba(0, 0, 0, 0.01) 95%,
            rgba(0, 0, 0, 0) 100%
          );
        }

        .cars-table > div > div {
          &:last-child {
            padding-left: $ui-padding * (2/3);
          }
          &:first-child {
            display: none;
          }
        }
      }

      & > span:first-child {
        padding: 0 $ui-padding * (2/3);
        padding-bottom: $ui-padding / 3;
        padding-top: $ui-padding;
      }
    }

    .ui-table {
      position: relative;
      overflow-x: auto;
      flex: 2;

      & > table {
        min-width: 100%;

        & > thead th {
          text-transform: capitalize;
          padding: 1.5rem 0 0.5rem 0;
          font-weight: normal;
          text-align: center;
        }

        & > tbody {
          @include box-shadow(inset 0 0 0 1px #efefef);
          border-collapse: collapse;
          position: relative;
          border-style: hidden; /* hide standard table (collapsed) border */
          border-radius: 10px;
          overflow: hidden;
        }

        & > tbody > tr {
          border-bottom: 1px solid $ui-table-border-color;

          &:nth-child(2n) {
            td > span:first-child {
              background: #f9f9f9;
            }
          }

          & > td {
            border-left: 1px solid $ui-table-border-color;
            position: relative;
            font-size: 0.8em;
            min-width: 2rem;
            font-size: 1em;
            padding: 0;

            & > span:first-child {
              padding-bottom: 100%;
              display: block;
              width: 100%;
            }

            &.active > span:last-child {
              background: map-get($theme-colors, 'success');
              color: #fff;
            }

            &.overlay > span:last-child {
              background: darken(map-get($theme-colors, 'success'), 15%);
            }

            /*
            &.rounded-left > span:last-child {
              border-bottom-left-radius: 50%;
              border-top-left-radius: 50%;
            }

            &.rounded-right > span:last-child {
              border-bottom-right-radius: 50%;
              border-top-right-radius: 50%;
            }
            */

            &.start-day {
              border-left-color: $ui-font-color-dark;
            }

            & > span:last-child {
              justify-content: center;
              align-items: center;
              position: absolute;
              display: flex;
              bottom: 0;
              right: 0;
              left: 0;
              top: 0;
            }
          }

          td:first-child {
            span:last-child {
              left: 1px;
            }
          }

          &:first-child {
            & > td > span {
              top: 1px;
            }

            & > td:first-child:not(.rounded-left) > span {
              border-top-left-radius: 10px;
            }
            & > td:last-child > span {
              border-top-right-radius: 10px;
            }
          }

          &:last-child {
            & > td > span {
              bottom: 1px;
            }

            & > td:first-child:not(.rounded-left) > span {
              border-bottom-left-radius: 10px;
            }
            & > td:last-child > span {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }

    .no-results,
    .loading {
      color: $ui-font-color-dark;
      padding: $ui-padding;
      text-align: center;
      padding-bottom: 0;
      font-weight: bold;
      font-size: 1.3em;
      max-width: 50%;
      margin: 0 auto;
    }
  }
}
