.ui-block-card-list-card {
  overflow: hidden;
  cursor: pointer;
  border: none;

  .ui-block-card-list-image {
    position: relative;

    .car-img {
      background-position: center center;
      background-size: cover;
      position: absolute;
      max-height: 100%;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

    .car-placeholder {
      max-width: 100%;
      opacity: 0.2;
    }

    .ui-block-card-list-card-ecv {
      border-top-right-radius: 4px;
      padding: $grid-gutter-width/4 $grid-gutter-width;
      color: $ui-font-color-dark;
      position: absolute;
      background: #fff;
      font-weight: bold;
      font-size: 0.75rem;
      bottom: -1px;
      left: -1px;
    }
  }

  .ui-block-card-list-card-body {
    padding: $grid-gutter-width;
  }

  .ui-block-card-list-card-header {
    padding: $grid-gutter-width/2 $grid-gutter-width;
    justify-content: space-between;
    color: $ui-font-color-dark;
    line-height: 0.75rem;
    font-size: 0.75rem;
    display: flex;

    & .danger {
      color: map-get($theme-colors, "danger");
    }

    & .warning {
      color: map-get($theme-colors, "warning");
    }

    & .success {
      color: map-get($theme-colors, "success");
    }
  }
}
