.ui-block-navbar {
  padding: $ui-padding / 3 $ui-padding / 2;
  background: #fff;
  max-width: 100%;
  height: 60px;

  & > .ui-progress {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.ui-block-navbar-menu-button {
  overflow: visible;
  width: 0;

  & > li {
    margin-left: -48px;
  }
}

.ui-block-navbar-title {
  font-weight: bold;
  color: #000;
}

.ui-block-navbar-menu-button {
  & .btn {
    background: transparent !important;
    box-shadow: none;
    margin: -1px 0;
    border: none;
  }

  & .hidden {
    opacity: 0;
  }
}
