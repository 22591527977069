.ui-container-common {
  padding-top: calc(1.5rem + 60px);
  padding: $ui-padding;
  background: white;
  position: relative;
  margin-top: 60px;
  max-width: 100%;
  display: block;
  flex: 1;

  & > * {
    position: relative;
  }

  &:not(.white)::before {
    background: $ui-bg-color;
    position: absolute;
    display: block;
    content: "";
    left: 50px;
    bottom: 0;
    right: 0;
    top: 0;
  }
}
