.ui-messagebox {
  justify-content: center;
  margin: 2 * $ui-padding;
  align-content: center;
  @extend .shadow;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  background: #fff;
  padding: $ui-padding;
  text-align: center;

  & > h5 {
    margin: 0;
    color: $ui-font-color-dark;
    display: block;
    font-weight: bold;
    font-size: 14px;
  }

  h5 + .content {
    margin-top: $ui-padding / 2;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $ui-padding / 2;
    text-align: center;
  }

  & > .content {
    & > i.icon-warning {
      font-size: 2em;
    }
  }
}

.ui-messagebox-modal {
  background: rgba(0, 0, 0, 0.35);
  justify-content: center;
  flex-direction: column;
  visibility: hidden;
  overflow-y: auto;
  position: fixed;
  display: block;
  z-index: 2000;
  height: 100%;
  right: 100%;
  opacity: 0;
  left: 0;
  top: 0;

  & > * {
    display: none;
  }

  transition: visibility 0s 0.75s, opacity 0.75s, right 0s 0.75s;

  &.show {
    transition: visibility 0s, opacity 0.75s, right 0s;
    visibility: visible;
    opacity: 1;
    right: 0;

    & > * {
      display: block;
    }
  }
}
