.ui-form-group {
  & > label {
    margin-bottom: $ui-padding / 3;
    text-transform: uppercase;
    font-weight: bold;
    color: #c7c7c7;
    font-size: 11px;
    &.hidden {
      clip: rect(0 0 0 0);
      position: absolute;
      overflow: hidden;
      margin: -1px;
      height: 1px;
      padding: 0;
      width: 1px;
      border: 0;
    }
  }

  .input-wrapper {
    position: relative;

    .ui-form-field {
      border-color: transparent;
      padding: $ui-padding;
      font-size: 0.9rem;
    }

    .ui-form-field:not(.border-0) {
      &:focus {
        border-color: map-get($theme-colors, "primary") !important;
      }
      &.is-invalid {
        border-color: map-get($theme-colors, "danger") !important;
      }
    }

    &:not(.icon-left) .ui-form-field.has-icon {
      padding-right: 2.5rem;
    }
    &.icon-left .ui-form-field.has-icon {
      padding-left: 2.5rem;
    }

    .ui-form-field-icon {
      position: absolute;
      font-size: 0.8rem;
      right: 1rem;
      top: 1rem;
    }

    i.ui-form-field-icon {
      line-height: 1rem;
      font-size: 1rem;
      height: 1rem;
      width: 1rem;
    }

    &.icon-left {
      .ui-form-field-icon {
        right: auto;
        left: 1rem;
      }
    }

    .ui-form-field:focus + .ui-form-field-icon {
      color: $ui-font-color-dark;
    }
  }
}
