.ui-ul {
  margin: $ui-padding / 2 0;
  flex-direction: column;
  list-style: none;
  display: flex;
  padding: 0;

  & > li {
    padding: 0;
    list-style: none;
    margin-bottom: $ui-padding / 2;
    display: flex;
    flex-direction: row;
    align-content: center;

    &.space-between {
      display: flex;
      justify-content: space-between;
    }
  }

  & > li:last-child {
    margin-bottom: 0;
  }
}
