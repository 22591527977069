.ui-dl {
  margin-bottom: 0;
  dt {
    text-transform: uppercase;
    font-size: 10px;
  }
  dd {
    text-transform: uppercase;
    color: $ui-font-color-dark;
    font-size: 12px;

    &.danger {
      color: map-get($theme-colors, "danger");
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > .ui-dl-dd-normal {
      text-transform: none;
    }
  }
}
