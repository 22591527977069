.ui-big-loading {
  $anim-len: 2s;
  z-index: 1;

  .loading {
    position: relative;
    margin: 50px auto;
    width: 100px;
    height: 80px;

    .bg {
      position: absolute;
      z-index: -10;
      width: 100px;
      height: 100px;
    }

    .gear {
      position: absolute;
      z-index: -10;
      width: 40px;
      height: 40px;
      animation: spin $anim-len infinite linear;
    }

    .two {
      left: 40px;
      width: 80px;
      height: 80px;
      animation: spin-reverse $anim-len infinite linear;
      color: red;
    }

    .three {
      top: 45px;
      left: -10px;
      width: 60px;
      height: 60px;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes spin-reverse {
      100% {
        transform: rotate(-360deg);
      }
    }

    .lil-circle {
      position: absolute;
      border-radius: 50%;
      box-shadow: inset 0 0 10px 2px #333;
      height: 100px;
      width: 100px;
    }

    .blur-circle {
      position: absolute;
      left: -19px;
      top: -19px;
    }
  }

  .text {
    color: #666;
    font-size: 14px;
    text-align: center;
  }
}
