$ui-slider-height: 6px;
$ui-slider-handle-height: 24px;

.ui-slider-wrapper {
  .ui-slider {
    height: 18px !important;
    position: relative !important;

    .rc-slider-rail,
    .rc-slider-step {
      height: $ui-slider-height;
    }

    .rc-slider-rail {
      background: #bcc4f8;
    }

    .rc-slider-track {
      background-color: map-get($theme-colors, "primary");
      height: $ui-slider-height;
    }

    .rc-slider-handle {
      border-color: map-get($theme-colors, "primary");
      margin-top: -$ui-slider-handle-height/2 + $ui-slider-height/2;
      margin-left: -$ui-slider-handle-height/2;
      height: $ui-slider-handle-height;
      width: $ui-slider-handle-height;
      border-width: 4px;

      &:focus,
      &:active,
      &:hover,
      &.rc-slider-handle-click-focused {
        border-color: map-get($theme-colors, "primary");
        box-shadow: none;
      }
    }
  }

  & > label {
    margin-bottom: -$ui-padding;
  }

  .ui-slider-value {
    margin-bottom: $ui-padding / 2;
    text-align: center;
    &.has-label {
      margin-top: -$ui-padding / 2;
    }
  }

  &.disabled {
    .rc-slider-rail {
      background-color: #e9ecef;
    }

    .rc-slider-track {
      background-color: #bfbfbf;
    }

    .rc-slider-handle {
      border-color: #bfbfbf !important;
    }
  }
}
