@mixin menu-nav-link {
  line-height: $ui-menu-icon-size;
  font-size: $ui-menu-icon-size * 0.65;
  padding-left: 0;
  color: #000;
  display: flex;

  & > i {
    color: map-get($theme-colors, 'primary');
    margin-right: $ui-padding;
    text-align: center;
    font-size: $ui-menu-icon-size;
    width: $ui-menu-icon-size;
  }
  & > img {
    height: $ui-menu-icon-size;
    width: $ui-menu-icon-size;
    margin-right: $ui-padding;
  }
}

.ui-block-menu {
  background: rgba(0, 0, 0, 0.3);
  animation: fadein 0.5s;
  overflow-y: auto;
  position: fixed;
  display: block;
  z-index: 1050;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  &:after {
    background-image: radial-gradient(
      circle farthest-side at 320% 50%,
      rgba(255, 255, 255, 0.1) 140%,
      #fff 140%
    );
    position: fixed;
    z-index: 1051;
    height: 100%;
    content: '';
    right: -70%;
    width: 100%;
    bottom: 0;
  }

  &:before {
    border-right: 1px solid #fff;
    right: calc(30% + -1px);
    position: fixed;
    background: #fff;
    z-index: 1051;
    content: '';
    bottom: 0;
    left: 0;
    top: 0;
  }

  .ui-block-menu-content {
    position: relative;
    padding: $ui-padding;
    padding-bottom: $ui-padding / 3;
    min-height: 100%;
    max-width: 70%;
    z-index: 1052;
  }

  #uiBlockMenuFakeButton {
    background: transparent !important;
    position: fixed;
    border: none;
    color: #fff;
    right: 12px;
    top: 5px;
  }

  .ui-block-menu-header {
    margin-bottom: $ui-padding;
    align-items: center;

    .ui-block-menu-avatar {
      @include box-shadow(0 4px 16px transparentize(map-get($theme-colors, 'primary'), 0.5));
      background: map-get($theme-colors, 'primary');
      justify-content: center;
      border-radius: 100%;
      align-items: center;
      margin-right: 1.5rem;
      height: 2.5rem;
      display: flex;
      color: #fff;
      width: 2.5rem;
      float: left;
    }

    .ui-block-menu-username {
      font-weight: bold;
      font-size: 16px;
      display: block;
      color: #000;
    }

    .ui-block-menu-brand {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      line-height: 1;
    }
  }

  .ui-block-menu-nav {
    .nav-link {
      @include menu-nav-link;

      .badge {
        @include box-shadow(1px 1px 5px '#45dfb1bf');
        background: map-get($theme-colors, 'success');
        border-radius: 100%;
        font-weight: normal;
        text-align: center;
        margin-left: 9px;
        line-height: 22px;
        margin-top: -4px;
        font-size: 12px;
        color: #fff;
        height: 22px;
        width: 22px;
        padding: 0;
      }
    }

    & > ul {
      margin-left: -$ui-padding;
      & > li {
        padding: $ui-padding / 4 $ui-padding;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .ui-block-menu-bottom {
    .nav-link {
      @include menu-nav-link;
      color: map-get($theme-colors, 'primary');
      font-weight: bold;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
