@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?t9ofe");
  src: url("fonts/icomoon.eot?t9ofe#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?t9ofe") format("truetype"),
    url("fonts/icomoon.woff?t9ofe") format("woff"),
    url("fonts/icomoon.svg?t9ofe#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

i[class^="icon-"],
i[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e900";
  color: #3b53fe;
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-attention:before {
  content: "\e903";
  color: #ff3d51;
}
.icon-calendar:before {
  content: "\e904";
  color: #3b53fe;
}
.icon-camera:before {
  content: "\e905";
}
.icon-car-create:before {
  content: "\e906";
}
.icon-car-details:before {
  content: "\e907";
}
.icon-car-list:before {
  content: "\e908";
}
.icon-car-traffic:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e90a";
}
.icon-edit:before {
  content: "\e90b";
  color: #3b53fe;
}
.icon-export:before {
  content: "\e90c";
  color: #3b53fe;
}
.icon-flake:before {
  content: "\e90d";
}
.icon-house:before {
  content: "\e90e";
}
.icon-leasing:before {
  content: "\e90f";
}
.icon-list-of-fuses:before {
  content: "\e910";
}
.icon-logout:before {
  content: "\e911";
  color: #3b53fe;
}
.icon-menu:before {
  content: "\e912";
}
.icon-order-create:before {
  content: "\e913";
}
.icon-password:before {
  content: "\e914";
}
.icon-returns:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e916";
}
.icon-status:before {
  content: "\e917";
}
.icon-sun:before {
  content: "\e918";
}
.icon-tires:before {
  content: "\e919";
}
.icon-tk-ek:before {
  content: "\e91a";
}
.icon-upload:before {
  content: "\e91b";
}
.icon-user:before {
  content: "\e91c";
}
.icon-warning:before {
  content: "\e91d";
  color: #e1e1e1;
}
